import { useEffect, useState } from "react";
import Search from "./Search";

const ProductsNavData = [
  {
    name: "All",
  },
  {
    name: "T-Shirts",
  },
  {
    name: "Hoodies",
  },
  {
    name: "Jeans",
  },
  {
    name: "Sneakers",
  },
];

const Products = ({ products }) => {
  const [activeCategory, setActiveCategory] = useState("All");
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    if (activeCategory === "All") {
      setFilteredProducts(products);
    } else {
      const newProducts = products.filter((product) => {
        return product.category === activeCategory;
      });
      setFilteredProducts(newProducts);
    }
  }, [activeCategory, products]);

  const handleClick = (category) => {
    setActiveCategory(category);
  };

  return (
    <div className="bg-white">
      <div style={{ borderBottom: "2px solid #eaeaea" }}>
        <div className="flex justify-between">
          <ul className="flex cursor-pointer py-3 px-6">
            {ProductsNavData.map((item, index) => (
              <li
                className={`py-2 px-6 bg-white rounded-lg text-black ${
                  activeCategory === item.name
                    ? "bg-black text-white hover:bg-black hover:text-white"
                    : "hover:bg-black hover:text-white"
                }`}
                key={index}
                onClick={() => handleClick(item.name)}
              >
                {item.name}
              </li>
            ))}
          </ul>
          <div className="flex justify-start px-8">
            <Search />
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">Products</h2>

        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
          {filteredProducts.map((product) => (
            <a key={product.id} href={product.href} className="group ">
              <div className=" aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="h-full w-full object-cover object-center group-hover:opacity-75"
                />
              </div>
              <h3 className="mt-4 text-sm text-gray-700">{product.name}</h3>
              <p className="mt-1 text-lg font-medium text-gray-900">
                {product.price}
              </p>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Products;
